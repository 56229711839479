@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Montserrat", sans-serif!important;
  font-optical-sizing: auto;
  font-style: normal;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.level_heading {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: white;
  background-color: #492780;
  border-color: inherit;
}

.global_btn {
  background-color: #492780;
  border-radius: 10px;
  padding: 12px 30px;
  border: none;
  color: white;
  transition: 0.6s;
}

.global_btn:hover {
  background-color: #492780;
  transform: translateY(-5px);
  transition: 0.6s;
  color: white;
}

.artist-btn {
  background-color: #492780;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;
  color: white;
  transition: 0.6s;
}

.artist-btn:hover {
  background-color: #036565;
  transform: translateY(-5px);
  transition: 0.6s;
  color: #fff;
}

.line-close-btn {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;
  color: #492780;
  border: 1px solid #492780;
  transition: 0.6s;
}

.line-close-btn:hover {
  background-color: #492780;
  transform: translateY(-5px);
  transition: 0.6s;
  color: #fff;
}

.main_head {
  position: relative;
}

.right_btn {
  position: absolute;
  right: 0px;
  top: 20px;
}

.product_list {
  padding: 45px 0px;
}

select {
  appearance: none;
  border: 1px solid #dee2e7;
  padding: 7px 20px;
  width: 180px;
  font-weight: 500;
}

select:focus {
  border: 1px solid #492780;
  outline: none;
}

.main_select {
  position: relative;
  display: inline-block;
}

.arrwoicon {
  position: absolute;
  right: 10px;
  top: 23%;
}

.arrwoicon i {
  color: #8b96a5;
}

.item_product {
  padding: 40px 0px;
}

.product_box {
  padding: 8px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
  /* Inside auto layout */
  position: relative;
}

.wishlist {
  background-color: white;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.product_list_box {
  padding: 15px 0px;
}

.product_box img {
  height: 275px;
  object-fit: cover;
}

.product_name {
  font-size: 18px;
  font-weight: 400;
  margin: 7px 0;
}

.product_rating ul {
  list-style-type: none;
  display: flex;
  gap: 5px;
  padding: 0px;
  color: #595959;
  margin-bottom: 7px;
}

.tiear_stauts_name img {
  width: 32px;
  object-fit: contain;
  height: 32px;
}

.tiear_stauts_name .name {
  color: #505050;
}

.active>.page-link,
.page-link.active {
  background-color: #ffffff;
}

.pagination_box {
  padding: 30px 0;
}

.active>.page-link,
.page-link.active {
  background-color: transparent !important;
}

.page-link {
  border: none !important;
  font-size: 22px !important;
  color: black !important;
}

.collectoion_autor_img {
  width: 44px;
  height: 44px;
  background-color: #d9d9d9;
  border-radius: 100%;
}

.collection_by {
  display: flex;
  gap: 10px;
  padding: 20px 0;
}

.collection_by h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0px;
}

.collection_by p {
  font-size: 14px;
  margin: 5px 0;
}

.tab-cus-buttons {
  display: inline-block;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #e3e3e3 !important;
  padding: 5px !important;
  border-radius: 16px !important;
  display: inline-flex !important;
}

.mine_tabs {
  display: none !important;
}

.tab-cus-buttons button.active {
  background-color: #492780 !important;
  color: white !important;
  padding: 10px 40px !important;
  border-radius: 16px !important;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.24);
  border: none;
}

.tab-cus-buttons button {
  background-color: white !important;
  color: black !important;
  padding: 10px 40px !important;
  border-radius: 16px !important;
  border: none;
}

.nav-tabs .nav-link.active {
  background-color: #492780 !important;
  color: white !important;
  padding: 10px 40px !important;
  border-radius: 16px !important;

}

.promisses_box {
  display: flex;
  gap: 15px;
}

.nav-tabs .nav-link {
  background-color: white !important;
  color: black !important;
  padding: 10px 40px !important;
  border-radius: 16px !important;
}

.mine_tabs {
  border: 1px solid #e3e3e3 !important;
  padding: 5px !important;
  border-radius: 16px !important;

}

.popular_collection .collection_grid img {
  height: 217px;
  object-fit: cover;
}

.popular_collection .tab-content {
  padding: 40px 0;
}

.promisses {
  padding: 40px 0px 80px;
}

.main_show_image {
  overflow: hidden;
}

.product_box {
  cursor: pointer;
  transition: 0.6s;
}

.product_box:hover {
  transform: translateY(-5px);
  transition: 0.6s;
}

.product_box:hover .product_name {
  text-decoration: underline;
}

.product_box {
  overflow: hidden;
  margin: 30px 8px 50px;
}

.product_box .main_show_image img {
  overflow: hidden;
  transition: 0.6s;
}

.product_box:hover .main_show_image img {
  transform: scale(1.2);
  overflow: hidden;
  transition: 0.6s;
}

.collection_grid {
  cursor: pointer;
  position: relative;
  transition: 0.6s;
}

.collection_grid img {
  height: 335px;
  object-fit: cover;
}

.collection_grid:hover {
  transform: translateY(-10px);
  transition: 0.6s;
}

.collection_grid:hover::after {
  opacity: 1;
}

.collection_grid::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #49278087;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: 0.6s;
}



.footetr_links li {
  margin-bottom: 6px;
}


.footetr_links ul li a:hover {
  color: #492780;
  font-weight: 600;
  transition: 0.6s;
}

footer {
  background-color: #f5f5f5;
  padding: 30px 0;
}

.footetr_links ul {
  list-style: none;
}

.custom-form {
  font-weight: 400;
  font-size: 16px;
  margin: 40px 70px;
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.custom-form .btn-primary {
  background-color: #036565;
  border-color: #036565;
}

.custom-form .form-group {
  position: relative;
  padding-top: 16px;
  margin-bottom: 16px;
}

.custom-form .form-group .animated-label {
  position: absolute;
  top: 20px;
  left: 0;
  color: #999999;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  cursor: text;
  transition: 0.2s ease all;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.custom-form .form-group .animated-label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45%;
  height: 2px;
  width: 10px;
  visibility: hidden;
  background-color: #036565;
  transition: 0.2s ease all;
}

.custom-form .form-group.not-empty .animated-label {
  top: 0;
  font-size: 12px;
}

.custom-form .form-group .form-control {
  position: relative;
  z-index: 1;
  border-radius: 0;
  border-width: 0 0 2px;
  border-bottom-color: #bfbfbf;
  height: auto;
  padding: 8px 0 5px;
}

.form-control:focus {
  box-shadow: none;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.custom-form .form-group .form-control:focus~.animated-label {
  top: 0;
  opacity: 1;
  color: #036565;
  font-size: 12px;
}

.custom-form .form-group .form-control:focus~.animated-label:after {
  visibility: visible;
  width: 100%;
  left: 0;
}

.custom-form h1 {
  font-size: 36px;
}

.custom-form p {
  font-weight: 400;
}

.remember_forgot {
  padding: 20px 0;
}

/* .remember_forgot input{ font-size: 26px; } */

.log_in {
  height: 100%;
}

.log_in img {
  height: 100%;
  object-fit: cover;
}

.log_in_using ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  padding: 0px;
  gap: 20px;
}

.log_in_using button {
  width: 100%;
}

.log_in_using .facebook {
  background-color: #1877f2;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 16px;
}

.log_in_using .google {
  background-color: white;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #d0d5dd;
}

.log_in_using .apple {
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid #d0d5dd;
}

.highlight_txt {
  color: #3bb3c1 !important;
  font-weight: 600;
}

.breadcrumbs ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.breadcrumbs ul li a {
  color: #8b96a5 !important;
}

.breadcrumbs ul li a:hover {
  color: #3bb3c1 !important;
}

.breadcrumbs ul li a i {
  font-size: 12px;
}

.product_list_page {
  padding: 40px 0;
}

.filter_product .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: white !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.filter_product .accordion-button:focus {
  box-shadow: unset !important;
}

.filter_product .accordion-button {
  font-weight: bold;
  border: none !important;
  box-shadow: unset !important;
  border-top: 1px solid #dee2e7 !important;
  border-radius: unset !important;
}

.filter_product .accordion-item {
  border: none !important;
}

.accordion-body {
  padding-top: 0px !important;
}

.catehory_box ul {
  list-style: none;
  padding: 0px;
}

.catehory_box ul li {
  list-style: none;
}

.catehory_box ul li a {
  color: #505050 !important;
  margin-bottom: 10px;
  display: block;
  font-weight: 400;
}

.catehory_box ul li a:hover {
  color: #3bb3c1 !important;
}

.color_filter_box label {
  cursor: pointer;
}

.color_filter_box label:hover {
  color: #3bb3c1 !important;
}

.color_filter_box .form-check {
  margin-bottom: 10px;
}

.color_filter_box .form-check input {
  font-size: 18px;
  border: 2px solid #bdbdbd !important;
}

.color_filter_box .form-check input:focus {
  box-shadow: unset !important;
}

.white_global_btn {
  color: white;
  border: 1px solid #e6e9ec;
  border-radius: 6px;
  background-color: white;
  color: #036565;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 10px;
  transition: 0.6s;
}

.white_global_btn:hover {
  background-color: #492780;
  color: white !important;
  transition: 0.6s;
}

.product_tags ul {
  padding: 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  list-style: none;
}

.min_max {
  gap: 10px;
}

.min_max input {
  width: 100%;
  border: 1px solid #dee2e7;
  padding: 7px;
  border-radius: 6px;
}

.min_max input:focus {
  border: 1px solid #036565;
  outline: none;
}

/* -----------------------------------newslettercss------------------------------------- */

.neweletter {
  background-image: url(../src/assets/images/newsbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px;
  border-radius: 32px;
}

.neweletter h3 {
  font-size: 32px;
}

.neweletter_main {
  padding: 50px 0px;
}

.email_subs_box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.email_subs_box input {
  background-color: white;
  border-radius: 16px;
  padding: 14px;
  border: 1px solid transparent;
  width: 60%;
}

.email_subs_box input:focus {
  border: 1px solid #492780;
  outline: none;
}

.neweletter p {
  font-size: 24px;

}





/* ------------------------header----------------------------------------------------- */

.right_option_bar ul li a svg {
  width: 26px;
  height: 26px;
}

.search-icon {
  position: relative;
}

.search-bar {
  width: 250px;
  background-color: white;
  -webkit-box-shadow: 0 5px 10px rgba(135, 138, 153, .12);
  box-shadow: 0 5px 10px rgba(135, 138, 153, .12);
  padding: 6px;
  position: absolute;
  top: 40px;
  right: 0px;
  transition: opacity 0.3s ease;
}

.mbile-heaader .search-bar {
  position: relative;
  width: 100%;
  top: unset;
  left: unset;
  padding: 0px;

}



.mobile-menu-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px
}

.mboiel-right ul {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 0px;
  font-size: 20px;
  color: #000;
  list-style: none;
}

.search-bar input {
  border: none;
  padding: 3px 10px;
  height: 38px;
  width: 100%;
  padding-right: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.search-bar input {
  border: none;
  padding: 3px 10px;
  height: 38px;
  width: 100%;
  padding-right: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.search-bar button {
  position: absolute;
  right: 10px;
  background-color: unset;
  border: unset;
  color: #2b2b2b;
  padding: unset;
  top: 14px;
}

.search-bar input:focus {
  outline: unset;
  border-color: #492780;
}

.header {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  z-index: 999;
  border: none;
  outline: none;
  background: #fff;
}

.main_inner_header {
  position: relative;
}

.top_logo_section {
  padding: 12px 0px 10px;
}


.main_inner_header .top_logo_section a {
  display: inline-block;
}

.main_inner_header .top_logo_section img {
  height: 50px;
}

.right_option_bar {
  position: absolute;
  top: 18px;
  right: 0px;
  top: 50%;
  /* left: 50%; */
  transform: translate(0%, -50%);
}

.right_option_bar ul {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-bottom: 0px;
}

.right_option_bar ul li {
  display: flex;
  align-items: center;
  position: relative;
}

.right_option_bar ul li a {
  font-size: 13px;
  color: #0e1214;
  font-weight: 600;
}

.right_option_bar i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 5px;
}

.right_option_bar .dropdown-menu.show {
  display: none;
  top: 100%;
  right: -40px;
}

header nav {
  display: unset;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: white;
  display: inline-block;
  justify-content: center;
  padding: 0px 15px;
  padding-left: 0px;
  z-index: 1;
}

.left_option_bar {
  display: inline-block;
  position: absolute;
  top: 18px;
  left: 0px;
  top: 50%;
  /* left: 50%; */
  transform: translate(0%, -50%);

}

header nav ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}

header nav ul li {
  list-style: none;
}

header nav ul li a:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

header nav ul li a {
  text-transform: uppercase;
  color: #2A2A2A;
  padding: 12px 12px;
  display: block;
  white-space: nowrap;
  margin: 0 0.5rem;
  font-size: .85rem;
  font-weight: 600;
  text-transform: uppercase;
}

/* -----------------------------------homaepage--------------------------------------------------- */
.img_box {
  position: relative;
}

.grid_box_artwork .img_box:hover .animation_btn {
  bottom: 10px;
  transition: 0.6s;
}

.grid_box_artwork .img_box img {
  transition: 0.6s;
}

.grid_box_artwork .img_box:hover img {
  transition: 0.9s;
  transform: scale(1.2);
}

.grid_box_artwork {
  overflow: hidden;
  cursor: pointer;
}

.grid_box_artwork_big:hover img {
  transition: 0.9s;
  transform: scale(1.2);
}

.grid_box_artwork_big img {
  transition: 0.9s;
}

.wish_list {
  background-color: transparent;
  color: white;
  border: none;
}

.grid_box_artwork_big:hover .animation_btn {
  bottom: 10px;
  transition: 0.6s;
}

.animation_btn .white_global_btn {
  border-radius: 30px;
  border: none;
}

/* .white_global_btn { background-color: white; color: black;} */
.animation_btn {
  position: absolute;
  bottom: -55px;
  width: 100%;
  padding: 5px 20px;
  gap: 10px;
  transition: 0.6s;
}

.grid_box_artwork_big {
  transition: 0.9s;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.main_homapage .popular_collection {
  margin-bottom: 60px;
}

.slider_sec img {
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.25));
  border-radius: 16px;
}

.slider_sec {
  padding: 40px 0;
}

.sub_tittle {
  background-color: #fffaeb;
  display: inline-block;
  color: #b54708;
  border-radius: 16px;
  padding: 3px 10px;
  font-weight: 600;
}

.cnt_slider h2 {
  color: white;
  font-size: 32px;
  margin: 12px 0;
}

.slider_big {
  position: relative;
}

.cnt_slider {
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
}

.slider_big img {
  height: 653px;
  object-fit: cover;
}

.global_card {
  border: 1px solid #cccccc;
  padding: 15px;
  border-radius: 16px;
}

.just_bought h1 {
  font-size: 28px;
}

.just_bought .sub_tittle {
  font-size: 12px;
}

.baought_list h3 {
  font-size: 16px;
  margin: 8px 0;
}

.list_tumb img {
  height: 118px;
}

.baought_list {
  padding: 10px 0;
  border-bottom: 1px solid #e1e1e1;
}

.baought_list img {
  filter: unset !important;
}


.left_global_heading {
  font-size: 48px;
}

.newartwork {
  padding: 50px 0;
}

.top_ten .tab-content {
  margin-top: 30px;
}

.top_ten {
  padding: 40px 0 80px;
}

.shode_box {
  background-color: #F9F9F9;
  border: 1px solid #DEE2E8;
  border-radius: 16px;
  padding: 30px;
}

.top_ten .tab-cus-buttons button.active {
  background-color: #7DBDBD !important;
  border: 1px solid #1F8E8E !important;
  padding: 10px 40px !important;
  box-shadow: unset !important;


}

.main_icons h5 {
  font-size: 48px;
}



.top_ten .tab-cus-buttons button:hover {
  background-color: #f4f4f4 !important;
}

.cat_box_inner p {
  margin: 10px 0;
  font-weight: bold;
}

.cat_box_inner img {
  border-radius: 16px;
  border: 4px solid #492780;
  width: 100%;
}

.blog_box h6 {
  font-size: 24px;
}

.user_details h2 {
  font-size: 14px;
  margin: 0px;
}

.user_details p {
  font-size: 14px;
  margin: 0px;
  color: #757575;
}

.all_categores {
  padding: 80px 0;
}

.blog_box {
  box-shadow: 0px 12px 40px -4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 20px;
  margin: 30px 0px;
}

.article {
  padding: 60px 0;
}

/* .join_community .main_ouetr {
  background-image: url(/src/assets/images/communtiybg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 40px;
  border-radius: 32px;
  background-size: cover;
} */

.main_icons {
  background-color: #E5F0F8;
  padding: 50px 20px;
  border: 5px solid #A8C6DE;
  border-radius: 20px;
}

.social_community {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  list-style: none;
  padding: 0px;
}

.social_community li {
  margin-bottom: 15px;
}

.faourtiw_list {
  position: relative;
}

.faourtiw_list img {
  border-radius: 32px;
  border: 3px solid white;
  width: 196px;
  height: 276px;
  object-fit: cover;
}

.cl_list {
  position: absolute;
  top: 0px;
  left: 15%;
}

.cl_list_two {
  position: absolute;
  top: 0px;
  left: 30%;
}

.cl_list_three {
  position: absolute;
  top: 0px;
  left: 45%;
}

.fav_box h2 {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.fav_box p {
  font-size: 18px;
}

.left_global_heading_small {
  font-size: 38px;
  margin-bottom: 20px;
}

.fav_page {
  padding: 60px 0;
}

.fav_page .artworks {
  background-color: white;
}


/* -----------------------------------artworks--------------------------------------------------- */
.img_box {
  overflow: hidden;
}

.grid_box_artwork {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grid_box_artwork img {
  height: 282px;
  object-fit: cover;
  overflow: hidden;
}

.artworks {
  padding: 60px 0;
  background: #F7F7F7;
}

.grid_box_artwork_big img {
  height: 580px;
  object-fit: cover;
}

.errmsg {
  color: rgb(255, 0, 0);
}





@media (min-width: 992px) {
  header nav .dropdown-menu {
    -webkit-box-shadow: 0 5px 10px rgba(135, 138, 153, .12);
    box-shadow: 0 5px 10px rgba(135, 138, 153, .12);
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    z-index: 1;
    min-width: 280px;
    column-count: 1;
    padding: 10px 0px !important;
    left: 0px;

  }

  .sticky-side-div {
    position: sticky;
    top: 0px;
  }

  .dropdown-hover:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu-list.submenu .nav-item .nav-link::before {
    left: 1.45rem;
  }

  .dropdown-menu-list .nav-item .nav-link:hover::before {
    opacity: 1;
  }

  .dropdown-menu-list .nav-item .nav-link::before {
    content: "";
    height: 5px;
    width: 5px;

    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 2px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    opacity: 0;
  }

  header nav ul li ul.submenu a {
    color: #2A2A2A;
    padding: 6px 12px;
    white-space: unset;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  header nav ul ul li a:hover {
    padding-left: 15px;
  }
}


@media screen and (max-width: 991px) {

  .log_in_using .apple {
    font-size: 14px;

    padding: 8px;
  }

  .log_in_using .google {
    font-size: 14px;
    padding: 8px;
  }

  .log_in_using ul {

    gap: 5px;
  }

  .log_in_using .facebook {
    font-size: 14px;
    padding: 8px;
  }

  .promisses {
    padding: 20px 0px;
  }

  .log_in img {
    display: none;
  }

  .custom-form {
    font-weight: 400;
    font-size: 16px;
    margin: 40px 0px;
  }

  .promisses_box {
    display: flex;
    gap: 15px;
    margin-bottom: 35px;
  }
}

/* 


@media (min-width: 991.98px) {


  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1530px !important;
  }
}
 */



@media screen and (max-width: 1300.98px) {

  .faourtiw_list img {
    border-radius: 32px;
    border: 3px solid white;
    width: 141px;
    height: 220px;
    object-fit: cover;
  }


  .top_ten .tab-cus-buttons button {
    background-color: white !important;
    color: black !important;
    padding: 10px 20px !important;
  }

  .top_ten .tab-cus-buttons button.active {

    padding: 10px 20px !important;
  }
}

@media screen and (max-width: 1450.98px) {

  .article {
    padding: 60px 0 1px;
  }

  .main_homapage .artworks {
    padding: 40px 0 15px;
  }

  /* .collection_grid img {
    height: 270px;} */

  .top_ten {
    padding: 0px 0 80px;
  }

  .popular_collection .collection_grid img {
    height: 180px;
  }

  .left_global_heading {
    font-size: 38px;
  }


}


@media screen and (max-width: 991.98px) {

  .fav_page .artworks {
    padding: 10px 0 40px;
  }


  .animation_btn {
    position: absolute;
    bottom: 10px;
  }

  .popular_collection .collection_grid img {
    height: 260px;
  }

  .tab-cus-buttons {
    margin-top: 20px;
  }

  header nav ul li a {
    text-transform: uppercase;
    color: #2A2A2A;
    padding: 12px 0px !important;
  }

  .container, .container-md, .container-sm {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 767.98px) {

  .fav_box {
    margin-bottom: 30px;
  }

  .fav_box h2 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .fav_box p {
    font-size: 16 px;
  }

  .neweletter p {
    font-size: 16px;
    font-weight: 500;
  }

  .neweletter {

    padding: 30px;
  }

  .footetr_links {
    margin-bottom: 30px;
  }
}


@media (max-width: 575.98px) {

  .grid_box_artwork_big img {
    height: 460px;
  }


  .top_ten .collection_grid img {
    height: 220px;
  }

  .shode_box {
    background-color: #F9F9F9;
    border: 1px solid #DEE2E8;
    border-radius: 16px;
    padding: 20px;
  }

  .email_subs_box button {
    text-align: end;
    margin-left: auto;
  }

  .email_subs_box input {
    width: 100%;
  }

  .email_subs_box {
    flex-direction: column;
    gap: 10px;
  }

  .top_ten .tab-cus-buttons img {
    width: 35px;
  }

  .top_ten .tab-cus-buttons button.active {
    padding: 10px 10px !important;
  }

  .tab-cus-buttons button {

    padding: 10px 10px !important;

  }

  .moble-logo img {
    width: 155px;
    height: auto;
  }
}


/* Edit-profile css */
.profile-add {
  padding: 50px 0px;
}

.inner-menus ul {
  padding-left: 0px;
  list-style: none;
  display: block;
}

.inner-menus li {
  margin-bottom: 12px;
}

.inner-menus li a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 0.5;
}

.inner-menus li a.active {
  color: #492780 !important;
  font-weight: bold;
  opacity: 1;
}

.account-profile-title h1 {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.04em;
  color: #000000;
  margin-bottom: 20px;
}

.account-content-box {
  background: #FFFFFF;
  border: 1px solid #AAAAAA;
  padding: 30px;
}

/* faq section customer */
.faq-page, .content-page-static {
  padding: 50px 0px;
}

.static-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: #000000;
}

.content-page-static h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: #000000;
  margin-top: 20px;
}

.tab-faq .nav-link.active,
.tab-faq .nav-link:hover {
  background: #492780;
  color: #fff !important;
  border: 1px solid #492780
}

.tab-faq .nav-link {
  padding: 9px 28px;
  background: #fff;
  border-radius: 16px;
  color: #492780 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #492780;
}

.tab-faq.nav.nav-pills {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.accordion-button::after {
  content: "+";
  border: 0px;
  background-image: unset;
  font-size: 30px;
  color: #492780;
  text-align: center;
}


.accordion-button:not(.collapsed)::after {
  content: "-" !important;
  background-image: unset;
  font-size: 38px;
  transform: unset;

}

.according-inner {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.16);
  border-radius: 18px;
  padding: 40px;
}

.according-inner .accordion-item {
  margin-bottom: 5px;
  border: 0px;
  padding: 0px;
}

.according-inner .accordion-body {
  padding: 0px;
  font-size: 15px;
  line-height: 25px;
  padding-top: 10px;
  color: #6F6C90;
}

.according-outer {
  margin-top: 40px;
}

.according-outer h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 39px;
  color: #333333;
}

.according-inner .accordion-body p {
  padding: 0px;
  font-size: 15px;
  line-height: 25px;
  color: #6F6C90;
}

.according-inner button.accordion-button {
  color: #492780;
  padding: 4px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.according-inner .accordion-button:not(.collapsed) {
  color: #492780;
  background-color: transparent;
  box-shadow: unset;
}

.according-inner .accordion-button:focus {
  box-shadow: unset;
}

.btn-check:checked+.btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check)+.btn:active:focus-visible {
  box-shadow: unset;
}

/* static-page-content */
.content-page-static p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.content-page-static h6 {
  font-weight: bold;
}

.content-page-static ol li {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.content-page-static ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-page-static ol li ol {
  margin-top: 5px;
}

.content-page-static ol li ol li {
  list-style: upper-latin;
}
.copyright-complai-box h2{
  font-size: 22px;
  font-weight: 600;
}
.copyright-complai-box{
padding: 20px;
background: #F9F9F9;
border-radius: 32px;
}

/* contact-us-section */
.contact-us-section h3 {
  font-weight: 500;
  font-size: 35px;
  line-height: 59px;
  color: #000000;
}

.contact-us-section p {
  font-size: 16px;
  color: #000000;
}

/* .contact-us-section {
  background-image: url('assets/images/bg-contact-us.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 40px 40px;
} */

.contact-us-section .form-control:focus {
  border-color: #492780;
  outline: 0;
  box-shadow: unset;
}

.contact-us-section .form-control {
  padding: 12px 16px;
  background: #fff;
  resize: none;
  border: 1px solid #fff;
  border-radius: 16px;
  font-size: 14px;
}

/* artist css custom */

.artist-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 30px);
  background-color: #fff;
  padding: 15px 0px;
}

.login-form {
  background: white;
  border-radius: 10px;
}

.login-title {
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0px;
  color: #492780;
}

.login-subtitle {
  color: #492780;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.login-button {
  margin-top: 20px;
  background-color: #492780;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.cutoms-login-artist .form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #131B33;
  margin-bottom: 5px;
}

.cutoms-login-artist .form-control:focus {
  border-color: #492780;
  outline: 0;
  box-shadow: unset;
}

.cutoms-login-artist .form-control {
  padding: 12px 16px;
  background: rgba(239, 241, 249, 0.6);
  border: 1px solid #E7E7E7;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;

}

.signup-link {
  margin-top: 20px;
}

.form-check-input:focus {
  box-shadow: unset;
}

.form-check-input:checked {
  background-color: #492780;
  border-color: #492780;
}

.cutoms-login-artist .form-check-input {
  width: 20px;
  height: 20px;
}

.Remember-me.form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.privacy-notice {
  margin-top: 10px;
  color: #6c757d;
  font-size: 12px;
}

.login-image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.logo-login {
  height: 38px;
}

.forgetpassword {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3A5377;
}

.Remember-me {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #708489;
}

.signup-link {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #131B33;
}

.signup-link a {
  color: #492780 !important;
  font-weight: bold;
}

.privacy-notice {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #131B33;
}

.steppers-inner.activestepss h6 {
  color: #492780;
}

.steppers-inner.activestepss {
  border-bottom: 2px solid #492780;
}

.steppers-inner {
  border-bottom: 2px solid #D5D8DD;

}

.steppers-outer {
  margin-bottom: 20px;
}

.steppers-outer p {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: #576275;

}

.steppers-inner h6 {
  margin-bottom: 0px;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #576275;
}


/* admin css */
.modal-all {
  z-index: 1204;
}

.modal-backdrop {
  z-index: 1203;
}

.modal-all .modal-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: #492780;
}

.modal-all .modal-content {
  padding: 10px;
}

.modal-all .modal-header {
  margin: 0px 15px;
  padding: 12px 0px;
  color: #000;
  font-size: 20px;
}

.flex-meni-edit .form-check {
  display: flex;
  align-items: end;
  gap: 8px;
}

.flex-meni-edit {
  display: flex;
  gap: 10px;
  align-items: center;
}

.role-menu-name p {
  margin-bottom: 0px;
}

.role-menu-name {
  display: flex;
  gap: 10px;
}

.title-admins-table {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 41px;
  margin: 0px;
  color: #492780;
}

.dorpdown-curtom .dropdown-toggle::after {
  display: none;
}

.card-cusotom .card-body{
  padding: 20px 15px;
}
.card-cusotom {
border-color: #EAECF0;
  background: #fff;
  border-radius: 20px;
}
.card-cusotom .card-footer{
  justify-content: end;
  gap: 10px;
  padding: 15px;
  display: flex;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: #EAECF0;
  background: #fff;
}
.card-cusotom .card-header{
  border-color: #EAECF0;
  background: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
  padding: 15px;
    line-height: 28px;
    color: #492780;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.form-control {
  appearance: auto;
}
.card-cusotom label{
  font-weight: 600;
}
.card-cusotom h2  {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #181818;
}
.view-admin-detials h5{
  color: #492780;
  font-size: 16px;
  font-weight: 600;

}
.view-admin-detials p{
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #838383;
}

.sidebar_logo {
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  text-align: center;

}
.sidebr-admin .simplebar-wrapper {
  padding: 0px 15px;
}

.menu-item-sidebar:hover h6 {
  color: #fff;
}
.menu-item-sidebar:hover svg path {
  color: #fff;
}
.header-top-bar {
  height: 70px;
} 
.searchbr-custom .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline{
  border: 1px solid #492780;
}
.searchbr-custom .Mui-focused{
  box-shadow: none;
  border: 1px solid #492780;
}
.badge-color .MuiBadge-badge.MuiBadge-standard {
  background: #492780;
  color: #fff;
}
.profile_tabs .MuiButtonBase-root .MuiListItemText-primary {
color: #000;
}
.profile_tabs .MuiButtonBase-root.MuiListItemButton-root:hover {
  background: #E8F4F4;
}
.table_samepattern {
background: #FFFFFF;
border: 1px solid #EAECF0;
box-shadow: 0px 0px 3px 1px rgba(102, 102, 102, 0.06);
border-radius: 12px;
}
.table_samepattern th{
  text-transform: unset;
  font-family: "Montserrat", sans-serif!important;
  font-weight: 500;
}
.table_samepattern td{
  text-transform: unset;
  font-family: "Montserrat", sans-serif!important;
  color: #555555;
  font-weight: 500;
}
.dorpdown-curtom .MuiButtonBase-root.MuiIconButton-root svg path{
  color: #492780;

}
.dorpdown-curtom .dropdown-menu a{
  color: #555555;
  font-size: 14px;
}
.dorpdown-curtom .dropdown-menu a svg{
  font-size: 18px;
  color: #555555;
}
.dorpdown-curtom .dropdown-menu {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 3px 1px #66666629;
}
@media (max-width: 767.98px) {
  /* faq page responsive */
  .according-inner {
    padding: 15px;
  }

  .tab-faq .nav-link {
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 24px;
  }

  .tab-faq.nav.nav-pills {
    gap: 10px;
  }

  .static-title, .contact-us-section h3, 
  .content-page-static h2 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .faq-page, .content-page-static {
    padding: 30px 0px 50px;
  }

  /* contactus-form */
  .contact-us-section {
    padding: 15px;
  }
}


.multi_add .MuiInputBase-formControl{
  background-color: #f5f7fb;
  padding: 4px;
}



.cutoms-login-artist select {

  background-color: #f5f7fb;
    padding: 10px;
}


.multi_check_input .MuiSelect-select {


  padding: 13px;
  background-color: #f5f7fb;
}

.rtl{

  direction: rtl;
  text-align: right;
}


.rtl .MuiDrawer-paperAnchorDockedLeft{left: unset; right: 0px;}

.rtl header{ margin-left: unset; margin-right: 260px;}

header.rtl{ margin: 0px!important;}



header.css-1ex3qo1{margin: 0px; }

header.css-mfpuvq-MuiPaper-root-MuiAppBar-root{margin: 0px; }


@media screen and (max-width: 1440.98px) {
  /* .rtl header{ margin-right: 0px;} */
}

.rtl th.MuiTableCell-alignStart { text-align: right;}

.rtl td.MuiTableCell-alignCenter{text-align: right!important;}

.rtl .rtl_text { text-align: left!important;}

.rtl .rtl_start{ text-align: right!important;}

.rtl header.css-9fxyiz{ margin-right: 260px; }

.rtl header.css-1ex3qo1{ margin-right: 0px!important;}