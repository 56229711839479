.title_admin {
  color: red;
}

.image_ui {
  /* width: 100%; */
  height: 150px;
  object-fit: contain;
  background: lightgray;
}

.image_box{
  position: relative;
}

.trash_btn{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}

.trash_btn{ border: none; background-color: #e8d9ff; color: red; height: 30px; width: 30px; border-radius: 100%; display: flex; justify-content: center; align-items: center;}

.view-admin-detials video{ width: 100%; height: 150px; }