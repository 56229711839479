/* Main container for centering content */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

/* Styling for the privacy policy content */
.privacy-policy {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #2c3e50;
}

h2,
h3 {
  font-size: 23px;
  /* color: #2980b9; */
  margin-top: 30px;
}

p {
  font-size: 16px;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

strong {
  color: #2c3e50;
}

/* Adding some responsiveness for smaller screens */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}
