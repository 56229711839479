body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error{
  color: red;
}
.swal_btn_ok{
  background-color: #008080;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border: none;
  outline: none;
  border-radius: 2px;
}


/* .my_admin{

  background-image: url(./assets/images/school.png);
  background-repeat: no-repeat;
  background-size: cover;
} */


input:focus{ box-shadow: unset; outline: unset;}
.edit_btn{ width: 100%; justify-content: start; color: #555555;     gap: 5px;}


.login_bg_img { height: 100vh; object-fit: cover;}


.lanuage_selector{ position: absolute; top: 20px; right: 20px;}

.lanuage_selector button{background-color: white!important;}


.card_tittle{     background-color: #e2d1ff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex
;
  align-items: center;
  font-size: 24px;
  justify-content: center;
  color: #492780;
  border: 1px solid #c3c3c3; transition: 0.6s}


  .link_go:hover .card_tittle{ background-color: #492780; color: white; transition: 0.6s;}

.my_card .MuiCardContent-root{display: flex; gap: 10px; align-items: center; }

.my_card  h6{ font-size: 12px!important; }


.my_logo img{ object-fit: contain!important;}

.my_logo  { background-color: #e7e7e7;}


/* Hide the actual file input */
.cs_uploader input[type="file"] {
  display: none;
}

/* Style the label to make it look like a button */
.custom-file-label {
  display: inline-block;
  padding: 28px 20px;
  border: 1px dashed #492780;
  background-color: #49278024;
  color: #492780;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Add hover effect to the label */
.custom-file-label:hover {
  background-color: #492780;
  color: white;
}

.custom-file-label:hover .custom-file-label { color: white;    }

/* Optionally style the input container */


.cs_uploader label{ width: 100%; display: flex; justify-content: center;  align-items: center;  flex-direction: column;} 

.cs_uploader  i{ font-size: 22px;}

.icon_social { display: flex; gap: 10px; margin: 0px;}
.icon_social li a{ width: 40px; height: 40px; border-radius: 100%; background-color: #492780;display: inline-block; color: white; display: flex; align-items: center;justify-content: center; border: 1px solid transparent; transition: 0.6s;}

.icon_social li a:hover{border: 1px solid #492780; background-color: white; transition: 0.6s;}
.icon_social li a:hover i{ color: #492780;}

.icon_social li a i{ color: white; font-size: 18px;}

.rating_box  {

  background-color: green;
  color: white!important;
  padding: 2px 10px;
  border-radius: 5px;
}
.image_ui {
  width: 188px!important;}
  .phone_btn{
    position: absolute;
    right: 15px;
    top: 0px;  }
  .phone_btn a{   border: 1px solid #492780;
    padding: 10px 15px;
    background-color: #492780;
    color: white !important;
    border-radius: 10px;
    font-weight: 500;
   }


    .my_schol_box{ position: relative;}

    .phone_btn span{ font-size: 12px!important; text-align: right;}

    .school_page #controlled-tab-example{flex-wrap: nowrap; overflow-x: scroll; }


       .school_page #controlled-tab-example::-webkit-scrollbar-track
    {
     
      background-color: white;
    }
    
       .school_page #controlled-tab-example::-webkit-scrollbar
    {
      width: 3px;
      height: 5px;
      background-color: #F5F5F5;
    }
    
       .school_page #controlled-tab-example::-webkit-scrollbar-thumb
    {
      background-color: #838383;
    }
    

    .school_page #controlled-tab-example button{ white-space: nowrap;}







    .school_page #user-info-tabs{flex-wrap: nowrap; overflow-x: scroll; }


    .school_page #user-info-tabs::-webkit-scrollbar-track
 {
  
   background-color: white;
 }
 
    .school_page #user-info-tabs::-webkit-scrollbar
 {
   width: 3px;
   height: 5px;
   background-color: #F5F5F5;
 }
 
    .school_page #user-info-tabs::-webkit-scrollbar-thumb
 {
   background-color: #838383;
 }
 

 .school_page #user-info-tabs button{ white-space: nowrap;}

    .school_page { padding: 15px 15px; border: 1px solid #dee2e6;     border-radius: 10px;}

    .subs_btn .list_btn { color: #492780!important;}

    .MuiTablePagination-selectLabel{ margin: 0px;}

    .MuiTablePagination-displayedRows{ margin: 0px;}


    @media screen and (max-width: 992px) {
      .phone_btn{ position: unset; display: inline-block; width: 100%;
        text-align: center; 
        margin-bottom: 30px;
      }

      .phone_btn a{ width: 100%; display: inline-block;}

      .left_image{display: none;}
     }
    

     .MuiInputBase-formControl:focus{ box-shadow: unset!important;}
     .MuiInputBase-formControl:hover fieldset{

      border-color: #492780!important;

     }

     .MuiInputBase-formControl.Mui-focused{ box-shadow: unset!important; border-color: #492780;}

     .form-select:focus{ box-shadow: unset!important;    border-color: #492780;}


     @media screen and (max-width: 1265.98px) {

      .rtl header{ margin-right: 0px!important;}


     }


     .login_form .MuiInputBase-colorPrimary:hover fieldset{ border-color: #492780; box-shadow: none;}

     .login_form .MuiInputBase-colorPrimary.Mui-focused{ box-shadow: unset!important; border-color: #492780;}

